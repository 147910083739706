@font-face {
  font-family: SpaceGrosteskLight;
  src: url('./fonts/SpaceGrotesk-Light.ttf');
}
@font-face {
  font-family: SpaceGrosterk;
  src: url('./fonts/SpaceGrotesk-Regular.ttf');
}

@font-face {
  font-family: SpaceGrostekBlond;
  src: url('./fonts/SpaceGrotesk-Bold.ttf');
}
body {
  font-size: 14px;
}
.nav-link {
  /*padding-right: 20px !important;*/
  margin-right: 10px;
  text-align: center !important;
}
.img-podcast-detalle{
  width: 100%;
}
.col-image {
  position: relative; /* Añadir posición relativa a la columna */
}

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
  display: none !important;
}
.grecaptcha-badge:hover {
  width: 256px !important;
}

.img-podcast {
  max-width: 70%; /* Ajusta el tamaño al 70% de la columna */
  
}
.img-home{
width:100%;
height: 100%;
}
.img-home-tarde{
  background-image: url('./img/tarde.png'); /* Reemplaza con la ruta de tu imagen */
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px; /* Añade espacio interno si es necesario */
  color: white; 
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-size: cover;
  text-align: center;
  font-size: 50px;
  max-width: 100% !important;
  padding: 10% 0;
}

.img-home-dia{
  background-image: url('./img/dia.png'); /* Reemplaza con la ruta de tu imagen */
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px; /* Añade espacio interno si es necesario */
  color: white; 
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-size: cover;
  text-align: center;
  font-size: 50px;
  max-width: 100% !important;
  padding: 10% 0;
}



.img-home{
  width: 100%;
}
html, body {
  font-family: 'SpaceGrostek', sans-serif !important;
}
.App {
  text-align: center;
}
.font-heder{
  color: #fff !important;
  font-family: 'GorditaRegular', sans-serif !important;
  font-size: 13px !important;
}
.font-heder:hover{
  color: #c4ad70!important;
}
.footer-font{
  font-size: 15px;

}
.title-det-podcast{
  font-size: 50px;
  font-family: 'SpaceGrostek', sans-serif !important;
  font-weight: 700!important;
  color:#2e2d2d;
}
.blog-title-detalle{
  color: #c4ad70;
  font-size: 35px;
  font-weight: 700!important;
}
.blog-subtitle{
  font-size: 15px;
  color: #000;
  display: flex; /* Crear un diseño flexible */
  align-items: baseline; 
}
.blog-bullet {
  width: 15px;
  position: relative;
  top: 3px;
}
.row-left-bg {
  background-color: #e5e5e5; /* Establecer el color de fondo para todo el lado izquierdo */
}
.bc-expert{
 padding: 15px;
 border-color: #535353!important;
}
.sociales-dor{
  font-size: 55px;
  color: #c4ad70;
  font-weight: 900!important;
}
.brading-dir{
  width: 100%;
  padding: 0;
  border-radius: 35px 0 0 35px;
}
.brading-dir-right{
  width: 100%;
  padding: 0;
  border-radius: 0 35px 35px 0;
}
.tendencias-black{
  font-size: 35px;

}
.contenido-bc{
  background-color: #2e2d2d;
  border-radius: 35px;
  color: #fff;
  padding: 35px!important;
}
.expert-tema{
  font-size: 25px;
  border-color: #e5e5e5!important;
}
.expert-desc{
  text-align: justify;
  font-size: 18px;
  padding: 15px;
  background-color: #fff;
}
.expert-tema-dor{
  font-size: 30px;
  font-weight: 700!important;
  color: #c4ad70;

}
.blog-subtitle img {
  vertical-align: middle; /* Alinear verticalmente la imagen con el texto */
  margin-right: 30px!important;
  margin-left: 25px; /* Añadir un pequeño espacio entre la imagen y el texto */
}
.ft-name{
  font-size: 25px!important;
  color:#c4ad70;
  position: relative;
  display: inline-block;
  padding: 0;

}
.ft-name::after {
  content: "";
  position: absolute;
  left: 10%; /* Ajusta el porcentaje según tu preferencia */
  right: 10%; /* Ajusta el porcentaje según tu preferencia */
  bottom: 0;
  width: 80%; /* Ancho del subrayado del 80% del texto desde el inicio */ /* Ancho del subrayado del 80% del texto desde el inicio */; /* Ancho del subrayado excluyendo las últimas 5 letras */
  height: 5px; /* Altura del subrayado */
  background: linear-gradient(90deg, transparent 0%, #c4ad70 0%, #c4ad70 100%);
}
.footer-tienes-exp{
  font-family: 'GorditaBlack', Courier, monospace;
  font-size: 45px;
  color: #d3d3d3;
  font-weight: 700;
  text-align: center;
  width: 100%;
  /*padding: 15px;*/
}
.footer-text-style{
  position: relative;
  /*bottom: -15px;*/
  top: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: #fff;
}
.footer-font-tienes{
  font-size: 45px;
  color: #d3d3d3;
  font-weight: 700;
  text-align: left;
  padding: 15px;
  width: 100%;
}
.footer-form{
  background-color: #2e2d2d!important; 
}
.logo{
  padding: 5px;
  padding-left: 10px;
}
.header{
  background-color: #2e2d2d!important;
  font-size: 15px;
}
.header-real{
  /*background-color: #043F51!important;*/
  background-color: #2E2D2D !important;
  font-size: 15px;
}
.footer-line{
  border-bottom: solid white .2rem;
}
.header-contact{
  /*font-size: 35px;*/
  font-family: 'GorditaRegular', sans-serif !important;
  font-size: 13px;
  color: #000!important;
  text-align: left;
 background-color: #c4ad70!important;
  border-radius: 35px;
  padding-left: 10px;
}
.slick-next:before {
  background: none;
  border: none;
  width: 35px!important;
  font-size: 50px; /* Tamaño de fuente */
  color:#c4ad70!important; /* Color de la flecha izquierda, en este caso, rojo */
  cursor: pointer;
}

/* Estilo para el botón de flecha derecha */
.slick-prev:before {
  background: none;
  border: none;
  font-size: 50px; /* Tamaño de fuente */
  color: #cacaca!important; /* Color de la flecha derecha, en este caso, verde */
  cursor: pointer;
}

.footer-contact{
  font-family: 'GorditaBold', Courier, monospace;
  border: solid #fff .2rem;
  border-radius: 15px;
  padding: 10px;
  font-size: 18px;
  color: #fff;
}
.arrow-contact{
  color:#c4ad70;
  width: 20px;
}
.img-services{
  width: 100%;
}
.section-footer{
  font-family: 'FilsonProRegular', sans-serif !important;
  font-size: 15px;
  color: #fff;
  /*padding-top: 30px;
  padding-bottom: 30px;*/
  position: static;
  background-color: #2e2d2d;
  text-align: center;
  vertical-align: middle;
}
.section-footer-top{
  /*padding-top: 30px;*/
  padding-left: 20px;
  padding-right: 20px;
}
.section-footer-green{
  background-color: transparent;
}

.font-title-seccions{
  font-size: 60px;
  line-height: 65px;
  font-weight: 700;
  text-align: center;
  color: #2e2d2d;
  padding: 25px;
}
.font-title-seccions-black{
  font-size: 80px;
  font-weight: 700;
  text-align: center;
  color: #fff;
}
.social-media{
  color: #2e2d2d;
  height: 40px;
  width: 40px;
  background-color: #c4ad70;
  padding: 5px;
  border-radius: 100%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  position: relative;
  top: 3px;
}
.text-gold{
  color:#c4ad70 ;
  font-size: 20px;
  text-align: center;
  align-items: center;
}
.logo-home {
   width: 40% !important;
   text-align: center;
}
.link-home-and{
  color: #fff;
  text-decoration: none;
  width: 100%;
  font-size: 80px;
  text-transform: uppercase;
}
.link-home{
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  font-size: 40px;
  line-height: 50px;
  text-transform: uppercase;
}
.link-home:hover{
  color: #fff;
  text-decoration: none;
}
.enlace-blog{
  text-decoration: none; /* Quítale el subrayado */
  color: #c4ad70; /* Establece el color deseado */
}
.enlace-blog:hover{
  text-decoration: none; 
  color: #c4ad70;
}
.enlace-entrevista{
  text-decoration: none;
  color: #000;
}
.enlace-entrevista:hover{
  text-decoration: none;
  color: #000;
}
.social-media-gold{
  color: #fff;
  height: 25px;
  width:25px;
  background-color: #c4ad70;
  padding: 5px;
  border-radius: 100%;
  text-align: center;
  margin: 5px;
 
  
}
.section-black{
  background-color: #2e2d2d;
  text-align: center;
  color: #fff;
}
.padding-zero{
  padding: 0!important;
}
.servicios-mkt{
  height: 100%;
  width: 100%;
  max-width: 105%;
  padding: 0!important;
}
.interviews-card{
  background-color: #2e2d2d;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  padding: 0;
  width: 70%;
}
.padding-podcast{
  padding: 5px;
}
.container-interviews{
  width: 70%;
 padding: 20px;
 cursor: pointer;
}
.img-interviews{
  width: 100%;
  height: 100%;
  padding: 15px;
 
}
.interviews-desc{
  padding: 25px!important;
  font-size: 15px;
  color: #fff;
  background-color: #2e2d2d;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
  width: 75%;
}
.social-media-interview{
  height: 10%!important;
  font-size: 20px;
  color:#cacaca;
  cursor: pointer;
  /*background-color: #cacaca;
  color:#2e2d2d;
  /*border-radius: 100%;*/
}
.name-inerview{
  color:#c4ad70;
  font-size: 30px;
  text-align: right;
}

.position-interview{
  font-size: 20px;
}
.temp-podcast{
  font-size: 35px;
  font-weight: 700;
  color: #c4ad70 ;
}
.podcast-bc{
  background-color: #f9f9f9!important;
}
.brading-img{
  width: 100%;
  padding: 0;
}
.brading-desc{
  background-color: #2e2d2d;
  font-size: 25px;
  color: #fff;
  border-radius: 0 35px 35px 0;
  vertical-align: middle;
  padding: 98px!important;
}
.padding-img{
  padding: 0!important;
}
.branding-title{
  font-size: 30px;
  line-height: 35px;
  font-weight: 700;
}
.image-with-text{
  display: flex; /* Hacer que los elementos estén en línea */
  align-items: center; /* Alinear verticalmente al centro */
  gap: 10px; 
}
.bc-brading-wite{
  color: #000!important;
  padding: 60px!important;
  margin: 0;
  display: flex; /* Añadir flexbox para centrar contenido verticalmente */
  flex-direction: column; /* Alinear contenido verticalmente */
  justify-content: center;
}
.bc-brading-gold{
  background-color: #c4ad70;
  color: #fff;
  padding: 10px 40px;
  margin: 0;
  display: flex; /* Añadir flexbox para centrar contenido verticalmente */
  flex-direction: column; /* Alinear contenido verticalmente */
  justify-content: center;
  border-radius: 35px 0px 0px 35px;
}
.bc-brading{
  background-color: #2e2d2d;
  color: #fff;
  padding: 10px 40px;
  margin: 0;
  display: flex; /* Añadir flexbox para centrar contenido verticalmente */
  flex-direction: column; /* Alinear contenido verticalmente */
  justify-content: center;
  border-radius: 0px 35px 35px 0px;
}
.bc-brading img {
  max-width:  15px; /* Evitar que las imágenes se distorsionen */
  max-height: 15px;
  height: auto;
  vertical-align: middle; /* Mantener la relación de aspecto original */
}
.branding-title-white{
  font-size: 30px;
  line-height: 35px;
  font-weight: 700;
  color: #fff;
}

.brading-desc-two{
  background-color: #535353;
  font-size: 15px;
  color: #fff;
  border-radius: 0 35px 35px 0;
  padding: 100px 25px 132px 12px!important;
  height: 85%;
}
.white{
  background-color: #fff!important;
}

.brading-desc-three{
  background-color: #c4ad70;
  font-size: 15px;
  color: #fff;
  border-radius: 0 35px 35px 0;
  padding: 25px!important;
  height: 85%;
  
}

.brading-desc-inver{
  background-color: #c4ad70;
  font-size: 25px;
  color: #fff;
  border-radius: 35px 0 0 35px;
  vertical-align: middle;
  padding:0!important;
}
.brading-desc-two-invert{
  background-color: #535353;
  font-size: 25px;
  color: #fff;
  border-radius:  35px 0 0 35px;
  padding: 25px!important;
}
.brading-desc-three-invert{
  background-color: #2e2d2d;
  font-size: 25px;
  color: #fff;
  border-radius: 35px 0 0 35px;
  padding: 98px!important;
}
.mkt-img {
  padding: 0 20px;
  width: 95%;
}
.footer-inmobil {
  width: 200px;
}
.blog-item-img {
  width: 110px;
  padding-right: 20px;
}
.elipse-style {
  width: 14px;
}
.player-v {
  width: 100% !important;
  /*height: 739px !important;*/
  height: calc( 100vw / 2.06 ) !important;
}
.mebs {
  width: 50px;
}
