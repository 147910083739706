.t4-container{
  position: relative;
}

h1{
  font-family: 'GorditaBlack', Courier, monospace;
  font-size: 68px;
  text-transform: uppercase;
  
  span{
    width: 100%;
    float: left;
    color: #fff;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
    transform: translateY(-50px);
    opacity: 0;
    animation: titleAnimation 4s ease infinite ;
  }
}
h1 span{
  animation-delay: 0.6s;
  -webkit-animation-fill-mode: forwards;

  &:first-child{
    animation-delay: 0.7s;
  }

  &:last-child{
    color: #ffff;
    animation-delay: 0.5s;
  }
}

.usechrome{
  font-size: 10px;
  color: #fff;
  font-family: helvetica, arial;
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  left: 0;
}

@keyframes titleAnimation {
    0% {
      transform: translateY(-50px);
      opacity: 0;
      -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
      clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
  }
  20% {
      transform: translateY(0);
      opacity: 1;
      -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 1%);
      clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 1%);
  }
  80% {
       transform: translateY(0);
       opacity: 1;
      -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 1%);
      clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 1%);
  }
  100% {
       transform: translateY(50px);
       opacity: 0;
      -webkit-clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
      clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
    
  }
}

@media (max-width: 460px) {
  h1{
    font-family: 'GorditaBlack', Courier, monospace;
    font-size: 35px;
    text-transform: uppercase;
    
    span{
      width: 100%;
      float: left;
      color: #fff;
      -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
      clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
      transform: translateY(-50px);
      opacity: 0;
      animation: titleAnimation 4s ease infinite ;
    }
  }
}
@media (max-width: 991px) {
  h1{
    font-family: 'GorditaBlack', Courier, monospace;
    font-size: 35px;
    text-transform: uppercase;
    
    span{
      width: 100%;
      float: left;
      color: #fff;
      -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
      clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
      transform: translateY(-50px);
      opacity: 0;
      animation: titleAnimation 4s ease infinite ;
    }
  }
}