.t01-container {
    width: 9.25em;
    height: 1.15em;
    font-size: 4rem;
    position: relative;
  }
  
  .t01-container div {
    position: absolute;
    left: 0;
    text-transform: uppercase;
    width: 100%;
    display: block;
    text-align: center;
  }
  
  .t01-upper {
    top: 0;
    height: 52.5%;
    color: #fff;
    overflow: hidden;
    z-index: 3;
    animation: t01-moveUp 4.2s ease-in-out infinite;
    background-color: #2e2d2d;
  }
  
  .t01-lower {
    bottom: 0;
    height: 100%;
    background: linear-gradient(180deg, #2e2d2d 52.5%, #fff 52.5%);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    z-index: 1;
    animation: t01-moveDown 4.2s ease-in-out infinite;
  }
  
  .t01-inside {
    font-family: 'Poppins', Courier, monospace;
    position: absolute;
    top: 40%;
    transform: translateY(-40%);
    text-align: center;
    z-index: 2;
    font-size: 2rem;
    color: #fff;
  }
  
  @keyframes t01-moveUp {
    0%,
    100% {
      top: 0;
    }
  
    50%,
    70% {
      top: -45px;
    }
  }
  
  @keyframes t01-moveDown {
    0%,
    100% {
      top: 0;
    }
  
    50%,
    70% {
      top: 30px;
    }
  }

  @media (max-width: 991px) {
    .t01-container {
      font-size: 2.5rem;
    }
  
    .t01-inside {
      font-size: 0.75rem;
    }
  }
  
  @media (max-width: 424px) {
    .t01-container {
      font-size: 2.5rem;
    }
  
    .t01-inside {
      font-size: 0.75rem;
    }
  }
  
  @media (max-width: 320px) {
    .t01-container {
      font-size: 2rem;
    }
  
    .t01-inside {
      font-size: 0.5rem;
    }
  }
  