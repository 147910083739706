.parrp {
  text-align: center;
  text-transform: uppercase;
  background: linear-gradient(90deg, #2E2D2D, #fff, #2E2D2D);
  letter-spacing: 5px;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-repeat: no-repeat;
  background-size: 80%;
  animation: shine 3.9s linear infinite;
  position: relative;
}

@keyframes shine {
  0% {
    background-position-x: -500%;
  }
  100% {
    background-position-x: 500%;
  }
}

/*  Checkout my other pens on  https://codepen.io/grohit/  */
