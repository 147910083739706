  @font-face {
    font-family: GorditaBlack;
    src: url('../fonts/Gordita-Black.otf');
  }
  @font-face {
    font-family: GorditaBold;
    src: url('../fonts/Gordita-Bold.otf');
  }
  @font-face {
    font-family: GorditaRegular;
    src: url('../fonts/Gordita-Regular.otf');
  }

  @font-face {
    font-family: GorditaLight;
    src: url('../fonts/Gordita-Light.otf');
  }
  
  a {
    text-decoration: none !important;
    color: inherit !important;
  }

  .form-check .form-check-input {
    border: 1px solid #c4ad70;
  }
  .modal-dialog {
    max-width: fit-content !important;
  }
  .form-floating>.form-select {
    border: 0 !important;
  }
  .form-control {
    border-radius: 14px !important;
    border: 1px solid #fff !important;
    color: #fff !important;
    background-color: transparent !important;
  }
  ::placeholder {
    color: #fff !important;
    opacity: 1; /* Firefox */
  }
  ._xsi02._1uQwZ {
    transform: scale(0.9) translateX(50px) translateY(50px) !important;
  }
  ._xsi02._KqFRp {
    transform: scale(0.9) translateX(0px) !important;
  }
  .react-photo-album--photo:hover {
    transform: scale(1.019);
    filter: brightness(50%);
  }
  
  .navbar-custom {
    background-color: #2E2D2D;
  }
  .header-space {
    height: 86px;
  }
  .no-padding {
    padding: 0 !important;
  }
  .header-des{
    background-color: #043f51!important;
    font-size: 15px;
  }
  .footer-font-tienes-desa{
    font-size: 45px;
    color: #d3d3d3;
    font-weight: 700;
    text-align: right;
    padding: 15px;
    width: 100%;
    background-color: #043f51;
  }
  .section-footer-desa{
    font-family: 'FilsonProRegular', sans-serif !important;
    font-size: 15px;
    color: #fff;
    height: 250px;
    position: static;
    background-color: #043f51;
    text-align: center;
    vertical-align: middle;
  }
  .desarrollo-text{
    font-size:65px ;
    font-weight: 800;
  }
  .desarrollo-descrip{
    font-size: 40px;
    line-height: 45px;
    color: #fff;
    font-weight: 600;
  }
  .desarrollo-descrip-tipo{
    font-size: 25px;
    line-height: 40px;
    color: #fff;
  }
  .desarrollo-descrip-costo{
    font-family: 'SpaceGrostekBlond', Courier, monospace;
    font-weight: bold;
    font-size: 25px;
    color: #ccb884;
  }
  .desarrollo-descrip-bg{
    position: absolute;
    bottom: -25px;
    background-color: #fff;
    color: #000;
    width: calc(100% - 50px);
    font-size: 15px;
    border-radius: 25px;
    padding: 15px 35px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .desarrollo-descrip-bg-v2{
    position: absolute;
    bottom: -25px;
    color: #000;
    width: calc(100% - 50px);
    font-size: 15px;
    padding-right: 35px !important;
  }
  .desarrollo-descrip-bg-v3{
    background-color: #fff;
    border-radius: 25px;
    padding: 15px 35px !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .desarrollo-desc-compl {
    background-size: cover;
    background-position: center;
    border-radius: 35px;
  }
  .desarrollo-descrip-img{
    padding: 25px 25px 100px 25px !important;
  }
  .mask {
    background-image: url(../img/mask.png);
    background-size: cover;
    background-position: center;
    border-radius: 35px;
  }
  .desarrollo-descrip-line{
    border-right: 3px solid #043f51;
    border-left: 3px solid #043f51;
  }
  .desarrollo-text-line {
    font-size: 13px;
    font-weight: 300;
  }
  .button-desa{
    border-radius: 18px;
    /*background-color: #2e2d2d;*/
    background-color: #C4AD70;
    color: #fff;
    text-align: center;
    width: 100%;
    padding: 13px 20px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    width: fit-content;
  }
  .price-principal {
    font-family: 'GorditaBold', Courier, monospace;
    font-size: 35px;
    color: #C4AD70;
  }
  /* Estilo para la galería de fotos */
.photo-gallery {
  display: flex;
  flex-wrap: wrap;
}

/* Estilo para las imágenes */
.photo {
  width: 20%;
  padding: 10px;
  box-sizing: border-box;
}

/* Estilo para "N fotos más" */
.more-photos {
  width: 20%;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
}
.detalle-desa{
  color: #043f51;
  font-size: 25px;
}
.detalle-localidad{
  color: #c4ad70;
  font-size: 55px;
  font-weight: 700;
}
.detalle-costo{
  color: #c4ad70;
  font-size: 35px;

}
.detalle-exteriores{
color: #043f51;
font-size: 35px;

}
.detalle-exterior{
  color:#707070;
  font-size: 15px;
}
.flecha-arriba{
  font-size: 25px;
  font-weight: 600;
  color: #043f51;
}
.caracteristicas-zona{
  font-size: 18px;
  color: #043f51;
}
.detalle-prom{
  background-color: #e5ebed;
  padding: 25px 30px;
}
.detalle-prom-text{
  color: #043f51;
  font-size: 20px;
  padding: 25px;
}
.elipse-style {
  width: 8px;
  margin-right: 5px;
}
.filter-container {
  /*background-color: #E5E5E5;*/
  border-radius: 15px;
  padding: 25px 25px
}
.avatar-icon {
  width: 45px;
}
.contact-info {
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
}
.input-label {
  padding: 15px 0;
  height: 36px;
}
.input-style {
  background-color: transparent !important;
  padding: 5px !important;
  border-bottom-color: #000 !important;
  border-radius: 0 !important;
}
.submit-btn {
  background-color: #fff !important;
  color: #043F51 !important;
  border-color: #043F51 !important;
}
.filter-banner {
  background-color: #000;
  font-size: 15px;
  color: #fff;
  font-family: 'GorditaBold', Courier, monospace;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
}
.filter-title {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
}
.sum-control {
  background-color: transparent !important;
  padding: 5px !important;
  color: #000 !important;
  cursor: pointer;
}
.simi-title {
  font-size: 40px;
  color: #043F51;
  font-weight: 800;
}
.simi-return {
  font-size: 25px;
  color: #043F51;
  font-weight: 400;
}
.prev-img {
  width: 100%;
  margin-bottom: 10px;
}
.prev-text {
  font-size: 12px;
  padding: 20px;
}
.prev-title {
  font-size: 15px;
  font-weight: 600;
}
.prev-btn {
  color: #ccb884;
  font-weight: 700;
  margin-top: 10px;
}
.p-banner {
  position: relative;
  background: url(../img/properties.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 700px;
}
.p-search-container {
  /*padding-top: 250px;
  text-align: center;*/
  position: absolute;
  bottom: 50px;
  margin: 0 auto;
  left: 0;
  right: 0;
}
.p-search {
  padding: 10px;
  background-color: #fff;
  border-radius: 15px;
  width: 800px;
}
.p-title {
  font-size: 55px;
  font-weight: 700;
  text-align: center;
  color: #fff;
}
.p-container {
  background-color: #2E2D2D;
  padding-top: 60px;
  padding-bottom: 60px;
}
.p-contact {
  background-image: url(../img/contact.png);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  /*max-width: 1295px;*/
  max-width: 100%;
}
.p-body-title {
  position: relative;
  top:10px;
  font-family: 'GorditaBlack', Courier, monospace;
  font-size: 70px;
  font-weight: 700;
  text-align: left;
  color: #fff;
  /*color: transparent;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: #fff;*/
  z-index: 10;
}
.p-body-title-blue {
  position: relative;
  top:20px;
  height: 105px;
  font-family: 'GorditaBlack', Courier, monospace;
  font-size: 50px;
  font-weight: 700;
  text-align: left;
  color: #fff;
  z-index: 10;
}
.p-body-title-back {
  background-color: #c4ad70;
  border-radius: 40px;
  position: absolute;
  left: -80px;
  height: 105px;
  width: 100%;
  z-index: 8;
}
.p-body-title-back-blue {
  background-color: #2E8FAC;
  border-radius: 40px;
  position: absolute;
  left: -80px;
  height: 105px;
  width: 100%;
  z-index: 8;
}
.p-body-title-border {
  position: absolute;
  border: 2px solid #fff;
  background-color: transparent;
  border-radius: 40px;
  right: 20px;
  height: 105px;
  width: 100%;
  z-index: 9;
}
.p-body-title-border-blue {
  position: absolute;
  border: 2px solid #2E8FAC;
  background-color: transparent;
  border-radius: 40px;
  right: 20px;
  height: 105px;
  width: 100%;
  z-index: 9;
}
/*.p-body-title-blue {
  background-color: #2E8FAC;
  border-radius: 40px;
  border: 3px solid #026684;
  width: fit-content;
  font-family: 'GorditaBlack', Courier, monospace;
  font-size: 50px;
  color: #fff;
  text-align: center;
  padding: 15px 30px 0px 30px;
}*/
.p-body-desc {
  font-family: 'GorditaRegular', Courier, monospace;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  color: #fff;
}
.carousel-style {
  width: 100%;
}
.nav-link-tabs {
  color: #000 !important;
}
.us-container {
  padding: 50px 25px;
  width: 100% !important;
}
.big_title {
  font-family: 'GorditaBlack', Courier, monospace;
  font-size: 68px;
  color: #fff;
  text-align: center;
}
.v-carousel-centered {
  height: 300px;
  position: relative;
  /*border: 3px solid green;*/
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  margin: 0 auto;
  left: 0;
  right: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.main-title {
  font-family: 'GorditaBlack', Courier, monospace;
  font-size: 40px;
  line-height: 45px;
  color: #2E8FAC;
  text-align: left;
  text-transform: uppercase;
  padding-bottom: 30px;
}
.main-desc {
  font-family: 'GorditaRegular', Courier, monospace;
  font-size: 15px;
  color: #000;
  text-align: left;
  /*padding-bottom: 30px;*/
  width: 100%;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.main-price {
  font-family: 'GorditaBold', Courier, monospace;
  font-size: 25px;
  color: #026684;
  text-align: right;
}
.carousel-style {
  background-image: url(../img/carousel_background.png);
  background-repeat: repeat-y;
  background-size: cover;
  position: relative;
}
.carousel-item-style {
  bottom: 20px;
  right: 10px;
}
.carousel-info {
  width: 90%;
}
.contact-title {
  font-family: 'GorditaBlack', Courier, monospace;
  font-size: 40px;
  line-height: 45px;
  color: #fff;
  text-align: left;
  text-transform: uppercase;
  padding-bottom: 30px;
}
.f-title-stroke {
  background-color: transparent;
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #fff;
}
.sent-button {
  border-color: #fff !important;
  color: #000 !important;
  background-color: #fff !important;
  border-radius: 18px !important;
}
.link-style {
  color: #fff !important;
}
.icon-style {
  color: #fff;
}
.icon-style-img {
  width: 18px;
}
.input-filter {
  border-color: #C4AD68 !important;
  color: #000 !important;
}
.input-filter::placeholder {
  color: #000 !important;
}
.stacked-carousel {
  position: relative;
  width: 100%;
  height: 400px;
}
.stacked-card {
  position: relative;
  width: 100%;
  height: 400px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.hight-lights-icon {
  width: 20px;
}
.hight-lights-text {
  position: relative;
  top: 2px;
}
.detail-title {
  font-family: 'GorditaBold', Courier, monospace;
  font-size: 55px;
  line-height: 60px;
  text-align: center;
  color: #033F51;
  text-transform: uppercase;
}
.detail-row {
  padding-bottom: 40px;
}
.line-subtitle {
  font-family: 'GorditaLight', Courier, monospace;
  color: #fff;
  font-size: 15px;
  text-align: center;
}
.line-title {
  font-family: 'GorditaBold', Courier, monospace;
  color: #fff;
  font-size: 40px;
  text-align: center;
}
.info-container {
  position: relative;
  top: 5px;
  padding: 20px;
}
.line-left {
  /*border-left: 2px solid #fff;*/
}
.detail-info-title {
  font-family: 'GorditaBlack', Courier, monospace;
  font-size: 35px;
  text-align: left;
  color: #000;
  text-transform: uppercase;
}
.detail-info-price {
  font-family: 'GorditaBold', Courier, monospace;
  font-size: 30px;
  text-align: left;
  color: #1798BF;
}
.detail-info-title2 {
  font-family: 'GorditaRegular', Courier, monospace;
  font-size: 25px;
  text-align: left;
  color: #1798BF;
}
.gray-background {
  background-color: #EBEBEB;
  position: absolute;
  width: 60%;
  height: -webkit-fill-available;
  top: 27%;
  z-index: -1;
}
.detail-info-button {
  background-color: #033F51;
  font-family: 'GorditaBold', Courier, monospace;
  font-size: 25px;
  color: #fff;
  border-radius: 30px;
  padding: 10px 20px;
  cursor: pointer;
  width: fit-content;
}
.detail-description {
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
}
.arrow-carousel {
  width: 40px;
  height: 40px;
  color: #616161;
}
.item-desc {
  font-size: 13px;
}
.text-mebs {
  font-size: 12px;
}
.privacy-footer {
  font-family: 'GorditaBold', Courier, monospace;
  font-size: 16px;
  cursor: pointer;
}
.mail-footer {
  font-family: 'GorditaRegular', Courier, monospace;
  font-size: 15px;
  letter-spacing: 1px;
  cursor: pointer;
}
.footer-image {
  display: none;
}




.app-container {
  position: relative;
  background-color: #fff;
  width: 1095.28px;
  /*height: 1841.89px;*/
  padding: 20px;
  z-index: 0;
  box-shadow: -2px -2px 18px -1px rgba(94,94,94,0.75);
  -webkit-box-shadow: -2px -2px 18px -1px rgba(94,94,94,0.75);
  -moz-box-shadow: -2px -2px 18px -1px rgba(94,94,94,0.75);
}
.app {
  text-align: left;
  width: 100%;
  /*height: 1841.89px;*/
  position: relative;
  /*width: 595.28px;
  height: 841.89px;*/
}
.bg-album {
  border-radius: 50px 0 50px 50px;
}
.logo-80 {
  width: 250px;
}
.print-hight-container {
  padding: 40px;
  border: 2px solid #c4ad70;
  border-radius: 20px;
  text-align: center;
}
.print-hight-number {
  font-family: 'SpaceGrostekBlond', Courier, monospace;
  font-size: 30px;
  color: #000;
}
.print-hight-title {
  font-family: 'SpaceGrosterk', Courier, monospace;
  font-size: 25px;
  color: #000;
}
.print-hight-line {
  width: 150px;
  border-bottom: 2px solid #c4ad70;
  margin-bottom: 15px;
}
.prit-price {
  font-family: 'SpaceGrostekBlond', Courier, monospace;
  font-size: 35px;
  line-height: 40px;
  border-radius: 20px;
  background-color: #d5b14e;
  padding: 10px;
  color: #fff;
}
.print-prop-title {
  font-family: 'SpaceGrostekBlond', Courier, monospace;
  font-size: 50px;
  line-height: 55px;
  color: #033F51;
  text-align: right;
  text-transform: uppercase;
}
.print-prop-desc {
  font-family: 'SpaceGrosteskLight', Courier, monospace;
  font-size: 18px;
  line-height: 23px;
  color: #000;
  text-align: justify;
}
.print-prop-location {
  font-family: 'SpaceGrosterk', Courier, monospace;
  font-size: 20px;
  line-height: 25px;
  color: #000;
  text-align: right;
}
.print-qr-contaner {
  border-radius: 60px 0 0 0;
  background-color: #d5b14e;
  padding: 70px 10px;
}
.print-footer {
  position: absolute;
  bottom: 20px;
}
.print-footer-color {
  background-color: #d5b14e;
}
.print-qr-text {
  font-family: 'SpaceGrosteskLight', Courier, monospace;
  font-size: 15px;
  text-align: center;
  padding-bottom: 5px;
}
.print-icon {
  text-align: center;
  color: #d5b14e;
  font-size: 50px;
}
.print-qr-contaner-header {
  border-radius: 0 0 0 60px;
  background-color: #d5b14e;
  padding: 70px 10px;
}
.print-logo-white {
  width: 200px;
}
.print-footer-text {
  font-family: 'SpaceGrosterk', Courier, monospace;
  font-size: 23px;
  color: #fff;
  padding: 15px 10px;
}
.print-footer-icon {
  color: #fff;
  font-size: 20px;
}
.print-footer-title {
  text-align: center;
  font-family: 'SpaceGrostekBlond', Courier, monospace;
  font-size: 35px;
}
.print-footer-rrss {
  color: #fff;
  font-size: 35px;
}
.print-logo-container {
  border-bottom-right-radius: 100px;
  background-color: #2d2c2c;
  padding: 30px 50px;
}
.print-logo {
  width: 100%;
}
.print-id-container {
  color: #000;
  background-color: #e6e6e6;
  padding: 20px;
  border-radius: 15px;
  font-weight: bolder;
  font-size: 22px;
}
.print-main-imgs {
  width: 100%;
  height: 350px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.print-carousel-style {
  background-color: #33697b;
  width: 100%;
  height: 370px;
}
.print-main-imgs-v2 {
  position: relative;
  top: -15px;
  left: -15px;
  width:400px;
  height: 355px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.print-main-imgs-v3 {
  width: 100%;
  height: 170px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.print-footer-container {
  border-top-right-radius: 100px;
  background-color: #2d2c2c;
  padding: 30px;
}
.print-line {
  width: 300px;
}
.print-card-container {
  padding: 15px;
  height: 132px;
  background-color: #F5F5F5;
}
.print-card-title {
  font-family: 'GorditaBlack', Courier, monospace;
  font-size: 20px;
  color: #004359;
  text-align: center;
}



.footer-space {
  height: 350px;
}
.footer-left {
  text-align: left;
}
.footer-right {
  text-align: -webkit-right;
}
.align-btn {
  text-align: -webkit-right;
}
.align-btn-left {
  text-align: -webkit-left;
}
.carousel-right-arrow {
  right: -15px;
}
.footer-font-contact{
  font-size: 35px;
  color: #fff!important;
  text-align: left;
  border-radius: 35px;
  padding-left: 10px;
}
.item-col-border {
  border-left: 1px solid #000;
}
.item-col {
  padding-top: 5px;
  padding-bottom: 5px;
}




/* Floating Social Media Bar Style Starts Here */

.fl-fl {
  /*background: #1A1A1A;*/
  background-color: transparent;
  position: fixed;
  left: 20px;
  bottom: 40px;
  z-index: 100;
  cursor: pointer;
}
.fl-fl a {
  
}
.float-icon {
  width: 30px;
  padding-left: 5px;
}
.float-icon-black {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}
.float-icon-hide {
  display: none;
}
/* Floating Social Media Bar Style Ends Here */




@media (min-width: 1321px) {
  .footer-space {
    height: 1050px;
  }
}

@media (max-width: 1199px) {
  .p-body-title {
    font-size: 30px;
    top:27px;
  }
  .p-body-title-blue {
    font-size: 30px;
    top:27px;
  }
  .main-title {
    font-size: 28px;
    line-height: 33px;
  }
  .price-principal {
    font-size: 30px;
  }
  .p-body-title-border, .p-body-title-border-blue {
    height: 90px;
  }
  .p-body-title-back, .p-body-title-back-blue {
    height: 90px;
  }
  .stacked-card, .stacked-carousel {
    height: 300px;
  }
}


@media (max-width: 991px) {
  .line-title {
    font-size: 22px;
  }
  .stacked-card, .stacked-carousel {
    height: 200px;
  }
  .detail-title {
    font-size: 45px;
    line-height: 50px;
  }
  .footer-tienes-exp, .footer-font-contact{
    text-align: center !important;
  }
  .mail-footer {
    font-size: 12px;
  }
  .footer-inmobil {
    width: 80% !important;
  }
  .p-body-desc {
    position: relative;
    top: 10px;
    font-size: 18px;
    line-height: 23px;
  }
  .big_title {
    font-size: 35px;
  }
  h1{
    font-size: 35px;
  }
  .link-style {
    font-size: 15px !important;
  }
  .properties-container {
    max-width: 95% !important;
  }
  .filter-container {
    padding: 5px 5px;
  }
  .hight-lights-text {
    font-size: 10px;
  }
  .price-principal {
    font-size: 20px;
  }
  .filter-banner {
    font-size: 12px;
  }
}

@media (max-width: 460px) {
  .logo {
    height: 50px;
  }
  .header-space{
    height: 76px;
  }
  .header-contact {
    padding: 10px 15px !important;
  }
  .p-banner {
    background-position: center;
  }
  .p-search {
    width: 100%;
  }
  .p-title {
    font-size: 35px;
    line-height: 40px;
    padding-bottom: 20px;
  }
  .p-body-title {
    font-size:40px;
    top: 10px;
  }
  .p-body-title-border, .p-body-title-border-blue {
    height: 70px;
  }
  .p-body-title-back, .p-body-title-back-blue {
    left: -50px;
    height: 70px;
  }
  .big_title {
    font-size: 35px;
  }
  h1{
    font-size: 35px;
  }
  .p-body-title-blue {
    font-size: 30px;
    height: 70px;
    top:17px
  }
  .stacked-card, .stacked-carousel {
    padding-top: 15px;
    height: 200px;
  }
  ._xsi02{
    height: auto;
  }
  .main-title{
    font-size: 30px;
  }
  .footer-space {
    height: 0px;
  }
  .p-contact {
    background-image: url();
  }
  .contact-title {
    font-size: 30px;
    line-height: 35px;
  }
  .footer-left {
    padding-bottom: 15px;
    text-align: center;
  }
  .footer-right {
    padding-top: 15px;
    text-align: center;
  }
  .desarrollo-descrip {
    font-size: 35px;
    line-height: 40px;
  }
  .desarrollo-descrip-tipo {
    font-size: 20px;
    line-height: 25px;
  }
  .desarrollo-descrip-bg {
    position: relative;
    bottom: -25px;
    width: 100%;
  }
  .desarrollo-descrip-img {
    padding: 25px 25px 50px 25px !important;
  }
  .desarrollo-descrip-bg-v2 {
    position: relative;
    bottom: -25px;
    width: 100%;
    padding-right: 0 !important;
  }
  .price-principal {
    text-align: -webkit-center;
    font-size: 25px;
  }
  .align-btn, .align-btn-left {
    text-align: -webkit-center;
  }
  .detail-title {
    font-size: 30px;
    line-height: 35px;
  }
  .carousel-right-arrow {
    right: 0px;
  }
  .footer-font-contact{
    position: relative;
    top: 15px;
    font-size: 35px;
    color: #fff!important;
    text-align: center;
    border-radius: 35px;
    padding-left: 10px;
  }
  .gray-background {
    height: auto;
    padding: 0px;
  }
  .footer-map-form {
    display: none;
  }
  .item-col-border {
    border: 0;
  }
  .line-title {
    font-size: 25px;
    line-height: 30px;
  }
  .btn-download {
    display: none;
  }
  .footer-tienes-exp, .footer-font-contact{
    text-align: center;
  }
  .detail-info-button {
    text-align: center;
  }
  .footer-contact {
    display: inline-block;
  }
  .footer-image {
    display: block;
  }
}